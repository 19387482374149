import React from "react";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import Widget from "../../../components/widget";
import EnigooNote from "../../../MyComponent/EnigooNote";
import {GiKnifeFork, HiDocumentReport, RiParkingBoxLine} from "react-icons/all";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import VerifyRights from "../../VerifyRights";

const ActionExtension = () => {

    const {t} = useTranslation();
    return <>
        <EnigooBreadcrumbs/>
        <Widget>
            <VerifyRights extension={[1]} action={[]} page={[]}>
                <Link to={"/action-extension/meal"}>
                    <EnigooNote left={<GiKnifeFork className={"text-enigoo-500"} size={50}/>}>
                        <div className={"flex flex-col space-y-2"}>
                            <div className={"text-xl font-bold text-enigoo-500"}>{t("mealExtension.plural")}</div>
                            <div>{t("mealExtension.description")}</div>
                        </div>
                    </EnigooNote>
                </Link>
            </VerifyRights>
            <VerifyRights extension={[2]} action={[]} page={[]}>
                <Link to={"/action-extension/parking"}>
                    <EnigooNote left={<RiParkingBoxLine className={"text-enigoo-500"} size={50}/>}>
                        <div className={"flex flex-col space-y-2"}>
                            <div className={"text-xl font-bold text-enigoo-500"}>{t("parkingExtension.single")}</div>
                            <div>{t("parkingExtension.description")}</div>
                        </div>
                    </EnigooNote>
                </Link>
            </VerifyRights>
            <Link to={"/action-extension/report"}>
                <EnigooNote left={<HiDocumentReport className={"text-enigoo-500"} size={50}/>}>
                    <div className={"flex flex-col space-y-2"}>
                        <div className={"text-xl font-bold text-enigoo-500"}>{t("device.reports")}</div>
                        <div>{t("actionExtension.reportDescription")}</div>
                    </div>
                </EnigooNote>
            </Link>


            {/*<Link to={"/action-extension/meal"}>
                <EnigooNote left={<MdEventSeat className={"text-enigoo-500"} size={50}/>}>
                    <div className={"flex flex-col space-y-2"}>
                        <div className={"text-xl font-bold text-enigoo-500"}>Usazování</div>
                        <div>Možnost </div>
                    </div>
                </EnigooNote>
            </Link>*/}
        </Widget>
    </>


}
ActionExtension.propTypes = {}
export default ActionExtension;
