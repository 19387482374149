import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import ErrorPage from "../Error/ErrorPage";
import {isArray} from "@craco/craco/lib/utils";


const VerifyRights = ({page,action,children,type="content",extension=null,onlyForManageUser}) =>{
    const {adminRights} = useSelector(
        state => ({adminRights:state.reducerAdmin.adminRights}),
        shallowEqual
    )
    if(extension && isArray(extension)){
        let corrects = 0;
        extension.forEach((item,index)=>{
            if(adminRights?.subject?.extensions && adminRights.subject.extensions.includes(item)){
                corrects++;
            }
        })

        if(corrects>0){
            return children;
        }else if(type==="route"){
            return <ErrorPage/>
        }else{
            return null;
        }

    }
    if(onlyForManageUser){
        if(adminRights.manageuser){
            return children;
        }else{
            return <></>
        }
    }
    if(adminRights.manageuser) return children;
    if(adminRights.superuser) return children;
    if(!adminRights.rights) return type==="route"?<ErrorPage/>:null;
    if(Array.isArray(page) && Array.isArray(action) && page.length===action.length){
        let corrects = 0;
        page.forEach((item,index)=>{
            if(adminRights.rights[item] && adminRights.rights[item][action[index]] && adminRights.rights[item][action[index]].is===true){
                corrects++;
            }
        })
        if(corrects>0){
            return children;
        }else if(type==="route"){
            return <ErrorPage/>;
        }else{
            return null;
        }
    }
    else if(Array.isArray(action)){
        let corrects = 0;
        action.map((item,index)=>{
            if(adminRights.rights[page] && adminRights.rights[page][item] && adminRights.rights[page][item].is===true){
                corrects++;
            }
        })
        if(corrects>0){
            return children;
        }else if(type==="route"){
            return <ErrorPage/>;
        }else{
            return null;
        }
    }
    else if(adminRights.rights[page] && adminRights.rights[page][action] && adminRights.rights[page][action].is===true){
        return children;
    }else{
        return type==="route"?<ErrorPage/>:null;
    }
}
export default VerifyRights;
