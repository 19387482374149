import React, {useEffect, useState} from "react";
import {useParams} from "react-router";
import SectionTitle from "../../../components/section-title";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {Field, Form} from "react-final-form";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {FiHelpCircle, FiLogOut, FiSend, FiX} from "react-icons/fi";
import {EnigooEmailField} from "../../../MyComponent/EnigooEmailField";
import {EnigooCustomField} from "../../../MyComponent/EnigooCustomField";
import {apiCreateUser, apiOnlineGetWebChannelData, apiOnlineLogoutWebChannel} from "./Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {useHistory} from "react-router-dom";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {axios} from "../../../axiosConfig";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import {useFilePicker} from "use-file-picker";
import {FiImage, FiInfo} from "react-icons/all";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import {useTranslation} from "react-i18next";

import {EnigooSelectMulti} from "../../../MyComponent/EnigooSelectMulti";
import arrayMutators from "final-form-arrays";
import OnlineExtensionSelect from "./OnlineExtensionSelect";
import EnigooToolTip from "../../../MyComponent/EnigooToolTip";


export const OnlineForm = () => {
    let [loading, setLoading] = useState(true);
    let [saving, setSaving] = useState(false);
    let [logouting, setLogouting] = useState(false);
    let [data, setData] = useState(null);
    let [initFormData, setInitFormData] = useState(null);

    const [openFileSelector, {filesContent, clear}] = useFilePicker({
        accept: ['.png', '.jpg', '.jpeg', '.gif'],
        readAs: 'DataURL',
        multiple: false,
        limitFilesConfig: {max: 1},
        maxFileSize: 2
    });

    const {t, i18n} = useTranslation()
    const {onlineConfig} = useSelector(
        (state) => ({
            onlineConfig: state.reducerOnline.onlineConfig
        }),
        shallowEqual
    )
    const {online, withAuthorization, withPhoto, requirePhoto} = {...onlineConfig};

    const history = useHistory();
    const dispatch = useDispatch()

    const {id} = useParams();

    const getLabel = (item) => {
        return item.name[i18n.language] ? item.name[i18n.language] : item.name["cs"];
    }

    useEffect(() => {
        getData();
    }, []);

    let dayItems = React.useMemo(() => {
        if (data && data.multiDay && data.days) {
            let days = [];
            data.days.forEach((item, index) => {
                days.push({
                    label: getLabel(item),
                    value: item.id
                })
            })
            return days;
        } else {
            return [];
        }
    }, [i18n.language, data])

    const setTranslations = (data) => {
        let lang = ["cs", "sk", "en", "de"];
        lang.forEach((l) => {
            data.translate && data.translate[l] && data.translate[l].forEach((item) => {
                if (item) {
                    i18n.addResourceBundle(l, 'translation', {[`customFields.${item.name}`]: item.label})
                }
            })
            if (data.textOnline && data.textOnline[l]) {
                i18n.addResourceBundle(l, 'translation', {[`online.textOnline`]: data.textOnline[l]})
            }

            if (data.textAfterAdd && data.textAfterAdd[l]) {
                i18n.addResourceBundle(l, 'translation', {[`online.textAfterAdd`]: data.textAfterAdd[l]})
            }
            if (data.customNameFirstname && data.customNameFirstname[l]) {
                i18n.addResourceBundle(l, 'translation', {[`online.customNameFirstname`]: data.customNameFirstname[l]})
            }
            if (data.customNameLastname && data.customNameLastname[l]) {
                i18n.addResourceBundle(l, 'translation', {[`online.customNameLastname`]: data.customNameLastname[l]})
            }
            if (data.customNameEmail && data.customNameEmail[l]) {
                i18n.addResourceBundle(l, 'translation', {[`online.customNameEmail`]: data.customNameEmail[l]})
            }
            if (data.groupsSelectName && data.groupsSelectName[l]) {
                i18n.addResourceBundle(l, 'translation', {[`online.groupsSelectName`]: data.groupsSelectName[l]})
            }
        })

    }

    const getData = () => {
        apiOnlineGetWebChannelData(id, (data) => {
            setData(data);
            dispatch({
                type: 'SET_ONLINE',
                onlineConfig: {
                    online: data.textOnline,
                    afterAdd: data.textAfterAdd,
                    afterAddAction: data && data.afterAdd && data.afterAdd.action ? data.afterAdd.action : null,
                    added: false,
                    onlineLogo: data.image,
                    withAuthorization: data.withAuthorization,
                    byPassword: data.byPassword,
                    byCode: data.byCode,
                    withPhoto: data.withPhoto,
                    requirePhoto: data.requirePhoto,
                    multiLanguage: data.multiLanguage,
                    multiDay: data.multiDay,
                    days: data.days,
                    defaultDays: data.defaultDays,
                    daysSelectName: data.daysSelectName,
                    viewMealSelect: data.viewMealSelect,
                    viewParkingSelect: data.viewParkingSelect,
                    mealSelectData: data.mealSelectData,
                    parkingSelectData: data.parkingSelectData

                }
            })
            if (data.defaultDays) {
                let initData = {
                    days: data.defaultDays
                }
                setInitFormData(initData);
            } else {
                setInitFormData(null);
            }
            setTranslations(data);
            setLoading(false);
        }, (error) => {
            if (error.response && error.response.data) {
                switch (error.response.data.error) {
                    case "USED_WEB_CHANNEL_CODE":
                        CogoToast.error(t("online.error.noData"))
                        setLoading(false);
                        setData(null);
                        window.location.reload();
                }
            } else {
                CogoToast.error(t("online.error.noData"))
                setLoading(false);
            }


        })
    }

    const onSubmit = (values) => {
        setSaving(true);
        let array = [];
        data.customField.map((item, index) => {
            array.push({
                "id": item.id,
                "value": (values[`cf-${item.id}`] !== undefined || values[`cf-${item.id}`] !== null) ? values[`cf-${item.id}`] : null
            });
        })
        values.customField = array;
        if (withPhoto && filesContent && filesContent[0]) {
            values.image = filesContent[0].content;
        }
        apiCreateUser(id, values, (data) => {
            dispatch({
                type: 'SET_ADDED',
                value: true
            })
        }, (error) => {
            let msg = t("online.error.registerNoOk");
            if (error?.response?.data?.error) {
                if (error.response.data.error === "EMAIL_NOT_VALID") {
                    msg += "\n" + t("online.error.enterValidEmail")
                }
            }
            CogoToast.error(msg);
            setSaving(false);
        })
    }

    const logout = () => {
        setLogouting(true);
        apiOnlineLogoutWebChannel(id, (data) => {
            axios.defaults.headers.common['authorization-online'] = null;
            localStorage.removeItem("ath-acc-en-on");
            CogoToast.success(t("online.error.logoutSuccess"));
            setLogouting(false);
            history.push("/app/online/" + id);
            window.location.reload();
        }, (error) => {
            CogoToast.error(t("online.error.logoutError"));
            setSaving(false);
        });
    }


    const _getDaySelectLabel = () => {
        if (data.daysSelectName && !data.multiLanguage) {
            return data.daysSelectName.cs;
        } else if (data.daysSelectName && data.multiLanguage) {
            return data.daysSelectName[i18n.language] ? data.daysSelectName[i18n.language] : data.daysSelectName.cs;
        } else {
            return t("action.days");
        }
    }

    const _renderBody = () => {
        if (!id) return <div></div>;
        if (loading) return <><EnigooLoader text={t("basics.loadingChannelData")}/></>;
        if (!data) return <></>;
        else return (
            <div className={"lg:px-10 px-3 py-2"}>
                <SectionTitle title={t("basics.accreditation")} subtitle={t("basics.registration")} right={
                    <div className={"flex flex-row space-x-2"}>
                        {withAuthorization &&
                            <EnigooButton onClick={logout} color={"white"} textColor={"enigoo-500"}
                                          extraStyle={"border border-enigoo-500"} loading={logouting}
                                          disabled={logouting} text={t("basics.logout")}
                                          icon={<FiLogOut className={"mr-2"}/>
                                          }/>}
                    </div>}/>
                <div className={"w-full text-center text-lg mb-3"}
                     dangerouslySetInnerHTML={{__html: data.textOnline ? t("online.textOnline") : ""}}/>
                <div className="flex flex-col">
                    <Form onSubmit={onSubmit} initialValues={initFormData}
                          mutators={{...arrayMutators}}
                          validate={values => {
                              let errors = {};
                              if (!values.lastname) {
                                  errors.lastname = t("basics.requireField");
                              }
                              if (!values.firstname) {
                                  errors.firstname = t("basics.requireField");
                              }
                              if (!values.email) {
                                  errors.email = t("basics.requireField");
                              }
                              data.customField.map((item, index) => {
                                  if (item.isMandatory && (values[`cf-${item.id}`] === undefined || values[`cf-${item.id}`] === null)) {
                                      errors[`cf-${item.id}`] = t("basics.requireField")
                                  }
                              })
                              data.checkingAttributes.map((item, index) => {
                                  if (item.required && (values[`ca-${item.id}`]) === false) {
                                      errors[`ca-${item.id}`] = t("basics.requireField");
                                  }
                              })
                              if (requirePhoto && filesContent && filesContent.length === 0) {
                                  errors.image = t("photo.choose");
                              }
                              if (data.viewGroups && !values.groups) {
                                  errors.groups = t("basics.requireField");
                              }
                              if (values.parkingItems) {
                                  errors.parkingItems = [];
                              }
                              return errors;
                          }}
                          render={({
                                       handleSubmit, values
                                   }) => {
                              return (
                                  <>
                                      <div className="grid grid-cols-2 lg:grid-cols-3 gap-2">
                                          {/* TOHLE TAM BUDE VŽDY */}
                                          <div>
                                              <EnigooTextField
                                                  label={data.customNameFirstname && data.customNameFirstname.cs ? t("online.customNameFirstname") + ": *" : t("basics.firstname") + ": *"}
                                                  name={"firstname"}
                                                  placeHolder={t("placeHolder.firstname")}/>
                                          </div>
                                          <div><EnigooTextField
                                              label={data.customNameLastname && data.customNameLastname.cs ? t("online.customNameLastname") + ": *" : t("basics.lastname") + ": *"}
                                              name={"lastname"}
                                              placeHolder={t("placeHolder.lastname")}/>
                                          </div>
                                          <div>
                                              <EnigooEmailField
                                                  label={data.customNameEmail && data.customNameEmail.cs ? t("online.customNameEmail") + ": *" : t("basics.email") + ": *"}
                                                  name={"email"}
                                                  placeholder={t("placeHolder.email")}
                                                  mandatory={true}/></div>

                                          {/*UZIVATELSKA POLE*/}
                                          {data.customField.map((item, index) => (
                                              <div><EnigooCustomField type={"online"} customField={item}
                                                                      key={index.toString()}/></div>
                                          ))}
                                          <div className={`${!data.multiDay ? "hidden" : ""}`}>
                                              <EnigooSelectMulti defaultValue={["value", "label"]}
                                                                 toolTip={t("webChannels.autoDaysToolTip")}
                                                                 items={dayItems} name={"days"}
                                                                 label={_getDaySelectLabel() + ":"}/></div>
                                          {data.viewGroups && <EnigooSelect
                                              label={data.groupsSelectName && data.groupsSelectName.cs ? t("online.groupsSelectName") + ": *" : t("groups.single") + ": *"}
                                              name={"groups"} items={data.groups}
                                              placeHolder={t("placeHolder.select")}/>}
                                          {withPhoto && <Field name={"image"}>
                                              {({input, meta}) => (
                                                  <>
                                                      <div className={"form-element mr-1"}>
                                                          <div
                                                              className="form-label flex flex-row"><>{t("photo.choosing") + ": "}
                                                              <FiHelpCircle
                                                                  className={"ml-2 text-red-900"} size={18}
                                                                  data-tip data-for={"_tooltip_online_choosePhoto"}/><EnigooToolTip content={t("photo.chooseToolTipOnline")} place={"right"} id={"_tooltip_online_choosePhoto"}/></>
                                                          </div>
                                                          {filesContent && filesContent[0] ?
                                                              <EnigooButton onClick={clear} color={"white"}
                                                                            textColor={"red-500"}
                                                                            extraStyle={"border border-red-500"}
                                                                            icon={<FiX/>}
                                                                            text={t("photo.cancelChoose")}/> :
                                                              <EnigooButton color={"white"}
                                                                            textColor={"enigoo-500"}
                                                                            extraStyle={"border border-enigoo-500"}
                                                                            onClick={() => openFileSelector()}
                                                                            text={t("photo.choose")}
                                                                            icon={<FiImage/>}/>
                                                          }
                                                          {meta.error && meta.touched &&
                                                              <div className="form-error">{meta.error}</div>}
                                                      </div>

                                                  </>
                                              )}
                                          </Field>}
                                          {(withPhoto && filesContent && filesContent[0]) &&
                                              <div>
                                                  <div className={"form-label"}>{t("photo.choosedPhoto") + ":"}</div>
                                                  <div
                                                      className={"border rounded grid place-content-center p-2 mb-1"}>
                                                      {filesContent && filesContent[0] ?
                                                          <img className={"max-h-sm"}
                                                               src={filesContent[0].content}
                                                               alt={"img"}/>
                                                          : <></>}

                                                  </div>
                                              </div>}
                                      </div>

                                      <OnlineExtensionSelect type={1} values={values}/>
                                      <OnlineExtensionSelect type={2} values={values}/>


                                      {data.checkingAttributes && data.checkingAttributes.length > 0 &&
                                          <>
                                              <div
                                                  className={"grid grid-cols-2 p-2 flex items-stretch w-full mb-2"}>
                                                  {data.checkingAttributes.map((item, index) => (
                                                      <>
                                                          <div
                                                              className={"w-full self-center"}>
                                                              <a
                                                                  href={item.url && item.url} target={"_blank"}
                                                                  className={"text-blue-700 underline"}>
                                                                  {item.label ?
                                                                      `${item.label} ${item.required ? " *" : ""}` : ""}</a>
                                                          </div>
                                                          <div><EnigooSwitch name={`ca-${item.id}`}
                                                                             label={''}/>
                                                          </div>
                                                      </>
                                                  ))}


                                              </div>
                                          </>}

                                      <div className={"grid lg:grid-cols-3 grid-cols-1"}>
                                          <div className={"text-sm italic mt-2"}>
                                              * {t("basics.requireField")}
                                          </div>
                                          <div className={"grid lg:col-start-3 "}>
                                              <EnigooButton onClick={handleSubmit} icon={<FiSend/>}
                                                            text={t("basics.send")}
                                                            loading={saving} disabled={saving}/>
                                          </div>
                                      </div>
                                  </>
                              )
                          }
                          }
                    />
                </div>
            </div>
        );
    }
    return _renderBody();
}
