import React from "react";
import {BiError, FiInfo} from "react-icons/all";
import PropTypes from "prop-types";

const EnigooNote = ({visible = true, children, color = "enigoo", icon = null, title = null, left = null}) => {
    const _renderIcon = () => {
        if (!icon) return <></>
        switch (icon) {
            case "error":
                return <BiError size={20} color={`${color}-500`}/>
            case "info":
            default:
                return <FiInfo size={20} color={`${color}-500`}/>
        }
    }
    if (visible) {
        if (left) {
            return (

                <div className={`flex flex-col p-3 pl-1 mb-2 rounded border border-${color}-500 bg-grey-200`}>
                    <div className={"flex flex-row space-x-6"}>
                        <div className={"m-2 pl-3 pr-5 pt-5 pb-5 rounded"}>{left}</div>
                        {icon || title ? <div className={"flex flex-row space-x-2 mb-5"}>
                            <div>{_renderIcon()}</div>
                            <div className={`text-${color}-500 font-bold`}>{title}</div>
                        </div> : <></>}
                        {children}
                    </div>
                </div>
            )
        } else {
            return (
                <div className={`flex flex-col p-3 pl-3 mb-2 rounded border border-${color}-500 bg-grey-200`}>
                    {icon || title ? <div className={"flex flex-row space-x-2 mb-5"}>
                        <div>{_renderIcon()}</div>
                        <div className={`text-${color}-500 font-bold`}>{title}</div>
                    </div> : <></>}
                    {children}
                </div>
            )
        }
    } else {
        return <></>;
    }
}
EnigooNote.propTypes = {
    visible: PropTypes.bool,
    children: PropTypes.any.isRequired,
    color: PropTypes.string,
    type: PropTypes.string,
    title: PropTypes.string
}
export default EnigooNote;
