import React, {useState} from 'react'
import {Link} from 'react-router-dom'
import {FiLogOut, FiUser} from 'react-icons/fi'
import {apiUserLogout} from "../../Admin/pages/Authorization/Actions";
import VerifyRights from "../../Admin/VerifyRights";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import EnigooDarkModeSwitch from "../../MyComponent/EnigooDarkModeSwitch";

const AccountLinks = () => {
    let [logouting, setLogouting] = useState();
    const dispatch = useDispatch();
    const {t} = useTranslation()
    const logout = () => {
        setLogouting(true);
        apiUserLogout((data) => {
            localStorage.removeItem("ath-acc-en");
            setLogouting(false);
            dispatch({
                type: 'RESET'
            })
            window.location.reload();
        }, (error) => {
            alert("Nepodařilo se odhlásit uživatele");
            setLogouting(true);
        })
    }

    return (
        <div className="flex flex-col w-full">
            <ul className="list-none">
                <VerifyRights page={"adminUser"} action={"getActualAdminUser"}>
                    <li key={"0"} className="dropdown-item">
                        <Link
                            to={'/user-profile'}
                            className="flex flex-row items-center justify-start h-10 w-full px-2">
                            <FiUser size={18} className="stroke-current"/>
                            <span className="mx-2">{t("basics.profile")}</span>
                        </Link>
                    </li>
                </VerifyRights>
                <button disabled={logouting} className={"flex flex-row items-center justify-start h-10 w-full px-2"}
                        onClick={logout}>
                    <FiLogOut size={18} className={"stroke-current"}/>
                    <span className="mx-2">{logouting ? t("basics.loggingOut") : t("basics.logout")}</span>
                </button>
                <EnigooDarkModeSwitch/>
            </ul>
        </div>
    )
}

export default AccountLinks
