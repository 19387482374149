import React, {useState} from 'react'
import {useHistory} from 'react-router-dom'
import Text from '../../../components/login/text'
import Logo from '../../../components/login/logo'
import Footer from '../../../components/login/footer'
import {apiUserLogin} from "../Authorization/Actions";
import {axios} from "../../../axiosConfig";
import {Form} from "react-final-form";
import {TextInput} from "../../../components/forms/text-inputs";
import CogoToast from "cogo-toast";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import EnigooLanguageDropDown from "../../../MyComponent/EnigooMultiLanguageField/EnigooLanguageDropDown";
import EnigooDarkModeSwitch from "../../../MyComponent/EnigooDarkModeSwitch";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";

const AdminUserLogin = ({setToken}) => {

    const history = useHistory();
    let [loading, setLoading] = useState(false)
    let submit = null;
    const dispatch = useDispatch();
    const {t,i18n} = useTranslation();
    const onSubmit = values => {
        setLoading(true)
        apiUserLogin(values, (data) => {
            axios.defaults.headers.common['authorization'] = data.token;
            localStorage.setItem("ath-acc-en", data.token);
            if(!data.subject.multiLanguage){
                i18n.changeLanguage("cs");
            }
            dispatch({
                type: "SET_RIGHTS",
                rights: data.rights,
                superuser: data.superuser,
                manageuser: data.manageuser,
                subject: data.subject
            });
            setLoading(false)
            setToken(data.token)
            history.push("/");

        }, error => {
            CogoToast.error(t("basics.nonValidLoginData"))
            setLoading(false)
        });
    }

    const onKeyDown = (event)=>{
        if(event.key==='Enter'){
            event.preventDefault();
            submit();
        }
    }

    return (
        <>
            <div className="w-full flex flex-row h-screen overflow-hidden">
                <div className={"w-full flex fixed"}>
                    <span className={"ml-auto mr-3 flex flex-row space-x-2"}>
                        <div className={"flex content-center mt-auto"}> <EnigooDarkModeSwitch/></div>
                    <EnigooLanguageDropDown loginPage={true} type={"login"}/>
                        </span>
                </div>
                <div
                    className="hidden lg:flex lg:flex-col w-1/2 text-white p-8 items-start justify-between relative bg-login-2">
                    <Logo/>
                    <Text/>
                    <Footer/>
                </div>
                <div className="w-full lg:w-1/2 bg-white p-8 lg:p-24 flex flex-col items-start justify-center">
                    <p className="text-2xl font-bold text-enigoo-500 mb-4">
                        {t("login.loginToAccreditation")}
                    </p>
                    <div className="flex flex-col w-full">
                        <Form onSubmit={onSubmit}
                              validate={values => {
                                  const errors = {};
                                  if (!values.login) {
                                      errors.login = t("basics.requireField")
                                  }
                                  if (!values.password) {
                                      errors.password = t("basics.requireField")
                                  }
                                  return errors;
                              }}
                              render={({handleSubmit, form, submitting, pristine}) => {
                                  submit = handleSubmit;
                                  return(
                                      <div className="w-full flex flex-col">
                                          <EnigooTextField label={t("adminUser.login")+": *"} type={"text"} name={"login"} onKeyDown={onKeyDown}
                                                           placeHolder={t("placeHolder.login")} outsideClassName={"w-full"}/>
                                          <EnigooTextField label={t("basics.password")+": *"} type={"password"} name={"password"} onKeyDown={onKeyDown}
                                                           placeHolder={t("placeHolder.password")} outsideClassName={"w-full"}/>
                                          <EnigooButton loading={loading} disabled={loading} onClick={submit} text={t("basics.login")}/>
                                      </div>
                                  )
                              }}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminUserLogin
