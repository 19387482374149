import React, {useEffect, useState} from "react";
import EnigooButton from "../../../MyComponent/EnigooButton";
import {FiCalendar, FiFilter, FiPlus, FiPrinter, FiSend, FiUserPlus, FiUsers} from "react-icons/fi";
import Widget from "../../../components/widget";
import {Form} from "react-final-form";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import EnigooBreadcrumbs from "../../../MyComponent/EnigooBreadcrumbs";
import {EnigooCustomField} from "../../../MyComponent/EnigooCustomField";
import Dropdown from "../../../components/dropdowns/dropdown-1";
import {userPrint} from "./CollectiveOperations/UserPrint";
import GroupsSelectField from "../../../MyComponent/Api/Admin/GroupsSelectField";
import {EnigooSelect} from "../../../MyComponent/EnigooSelect";
import CogoToast from "cogo-toast";
import {SendMailModal} from "../EmailQueue/SendMailModal";
import VerifyRights from "../../VerifyRights";
import {AssignUserToGroupModal} from "./CollectiveOperations/AssignUserToGroupModal";
import {AiOutlineFileExcel, AiOutlineFilePdf} from "react-icons/all";
import {CopyUserToActionModal} from "./CollectiveOperations/CopyUserToActionModal";
import UserTable from "./UserTable";
import {UserPrintErrorModal} from "./CollectiveOperations/UserPrintErrorModal";
import {userExport} from "./UserExport";
import {MailTemplateSelectField} from "../../../MyComponent/Api/Admin/MailTemplateSelectField";
import {useTranslation} from "react-i18next";
import ActionDaysSelect from "./Form/ActionDaysSelect";
import UserAssignDaysModal from "./CollectiveOperations/UserAssignDaysModal";
import UserAssignZoneModal from "./CollectiveOperations/UserAssignZoneModal";
import {EnigooDateTimeFieldv2} from "../../../MyComponent/EnigooDateTimeFieldv2";
import {useHistory} from "react-router-dom";

const User = () => {
    let [printing, setPrinting] = useState(false);
    let [selectedRows, setSelectedRows] = useState([]);
    let [openModal, setOpenModal] = useState(false);
    let [filtering, setFiltering] = useState(false);
    let [printError, setPrintError] = useState(null);
    let [openGroupModal, setOpenGroupModal] = useState(false);
    let [openActionModal, setOpenActionModal] = useState(false);
    let [selectAll, setSelectAll] = useState(false);

    const {t} = useTranslation()
    let filterSubmit = null;
    let filterDirty = null;
    const history = useHistory();
    const dispatch = useDispatch();

    const {actualAction} = useSelector(
        state => ({customField: state.reducerAdmin.customField, actualAction: state.reducerAdmin.actualAction}),
        shallowEqual
    )
    const {filterUser} = useSelector(
        state => ({
            filterUser: state.reducerAdmin.filterUser
        }),
        shallowEqual
    )

    const {openFilter, filtered, filterData} = filterUser;

    useEffect(() => {
        if ((!filterData || !filterData.entranceDate)) {
            let date = new Date().toISOString();
            date = date.slice(0, date.indexOf("T"));
            dispatch({
                type: 'SET_FILTER_DATA',
                filterData: {
                    entranceDate: date
                }
            })

        }
    }, []);


    useEffect(() => {
        const handleKeyDown = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'p') {
                event.preventDefault();
                if (filtered && selectAll) {
                    userPrint(selectedRows, setPrinting, actualAction, setPrintError, "print", filterData, selectAll, null, false, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                } else if (selectAll) {
                    userPrint(selectedRows, setPrinting, actualAction, setPrintError, "print", null, true, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                } else {
                    userPrint(selectedRows, setPrinting, actualAction, setPrintError, "print", null, selectAll, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                }
                console.log('Ctrl/Command + P was pressed');
            }
            if ((event.ctrlKey || event.metaKey) && event.key === 'a') {
                event.preventDefault();
                setSelectAll(!selectAll);
                console.log('Ctrl/Command + A was pressed');
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [selectedRows, selectAll]);

    const _renderBody = () => {
        return <UserTable selectedRows={selectedRows} setSelectedRows={setSelectedRows} selectedRowsAll={selectAll}
                          setSelectedRowsAll={setSelectAll} filtering={filtering} setFiltering={setFiltering}/>
    }

    const getPlatformShortCut = (letter)=>{
        const platform = navigator.userAgent.toLowerCase();
        if(platform.includes("mac")){
            return ("(⌘Cmd + "+letter.toUpperCase()+")");
        }else{
            return ("(Ctrl + "+letter.toUpperCase()+")");
        }
    }

    const filter = (values) => {
        if (filterDirty) {
            let array = [];
            actualAction.customField.map((item, index) => {
                if ((values[`cf-${item.id}`] !== undefined || values[`cf-${item.id}`] !== null)) {
                    if (item.customFieldType.type === "BOOL") {
                        if (values[`cf-${item.id}`] === 1) {
                            array.push({
                                "id": item.id,
                                "value": true
                            })
                        }
                        if (values[`cf-${item.id}`] === -1) {
                            array.push({
                                "id": item.id,
                                "value": false
                            })
                        }
                    } else {
                        array.push({
                            "id": item.id,
                            "value": values[`cf-${item.id}`]
                        });
                    }
                }
            })
            if (values.printed && values.printed === -1) {
                values.wasPrint = false;
            } else if (values.printed && values.printed === 1) {
                values.wasPrint = true;
            } else {
                values.wasPrint = undefined;
            }
            values.customField = array;
            values.actionId = actualAction.actualAction;
            if (filtered === true) {
                dispatch({
                    type: 'SET_FILTERED',
                    filtered: false
                })
            }
            dispatch({
                type: 'SET_FILTERED',
                filtered: true
            })
            dispatch({
                type: 'SET_FILTER_DATA',
                filterData: values
            })
            dispatch({
                type: 'CHANGE_PAGE_INDEX',
                pageIndex: 0,
            })
        }
    }

    const onKeyDownFilter = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            filterSubmit();
        }
    }

    const _renderFilterForm = () => {
        if (openFilter) {
            return (
                <div className={"flex flex-col"}>
                    <Form onSubmit={filter} initialValues={filterData}
                          render={({handleSubmit, values, dirty}) => {
                              filterDirty = dirty;
                              filterSubmit = handleSubmit;
                              return (
                                  <>
                                      <div className={"grid grid-cols-2 lg:grid-cols-6 gap-2"}>
                                          <div className={"col-start-2 lg:col-start-6"}>
                                              <div
                                                  className={"text-right "}
                                                  onClick={() => {
                                                      dispatch({
                                                          type: 'SET_OPEN_FILTER',
                                                          openFilter: false
                                                      })
                                                  }
                                                  }
                                                  style={{cursor: "pointer"}}>❌
                                              </div>
                                          </div>
                                      </div>
                                      <div className="grid grid-cols-2 lg:grid-cols-6 gap-2">
                                          <EnigooTextField label={t("accreditation.numberOfAcc") + ":"} type={"number"}
                                                           name={"id"}
                                                           placeHolder={t("accreditation.numberOfAcc")}
                                                           onKeyDown={onKeyDownFilter}/>
                                          <EnigooTextField name={"firstname"} label={t("basics.firstname") + ":"}
                                                           placeHolder={t("basics.firstname")}
                                                           onKeyDown={onKeyDownFilter}/>
                                          <EnigooTextField name={"lastname"} label={t("basics.lastname") + ":"}
                                                           placeHolder={t("basics.lastname")}
                                                           onKeyDown={onKeyDownFilter}/>
                                          <EnigooTextField name={"email"} label={t("basics.email") + ":"}
                                                           placeHolder={t("basics.email")} onKeyDown={onKeyDownFilter}/>
                                          {actualAction.customField && actualAction.customField.map((item, index) => (
                                              <EnigooCustomField filter={true} customField={item}
                                                                 key={index.toString()} onKeyDown={onKeyDownFilter}/>
                                          ))}
                                          <GroupsSelectField placeHolder={t("placeHolder.selectMulti")}
                                                             name={"groupsId"} label={t("groups.single") + ":"}
                                                             isMulti={true}
                                                             filter={true} onKeyDown={onKeyDownFilter}/>
                                          <EnigooSelect placeHolder={t("placeHolder.select")}
                                                        label={t("basics.printed") + ":"} name={"printed"}
                                                        items={[{value: 1, label: t("basics.yes")}, {
                                                            value: -1,
                                                            label: t("basics.no")
                                                        }]}
                                                        defaultValue={["value", "label"]} isClearable={true}
                                                        onKeyDown={onKeyDownFilter}/>
                                          <MailTemplateSelectField placeHolder={t("placeHolder.selectMulti")}
                                                                   name={"wasSend"} label={t("email.sended") + ":"}
                                                                   isMulti={true} addNoMail={true}
                                                                   toolTip={t("email.sendedToolTip")}
                                                                   onKeyDown={onKeyDownFilter}/>
                                          <EnigooSelect placeHolder={t("placeHolder.select")}
                                                        label={t("entrance.plural") + ":"}
                                                        defaultValue={["value", "label"]}
                                                        toolTip={t("entrance.entranceFilterToolTip")}
                                                        name={"entrance"}
                                                        items={[{value: 1, label: t("entrance.enter")}, {
                                                            value: 2,
                                                            label: t("entrance.enterDay")
                                                        }, {value: 3, label: t("entrance.notEnter")}, {
                                                            value: 4,
                                                            label: t("entrance.notEnterDay")
                                                        }]} onKeyDown={onKeyDownFilter}/>
                                          {values.entrance && (values.entrance === 2 || values.entrance === 4) &&
                                              <EnigooDateTimeFieldv2 name={"entranceDate"}
                                                                     label={t("entrance.date") + ":"}
                                                                     showTimeSelect={false}
                                                                     onKeyDown={onKeyDownFilter}/>}
                                          <EnigooTextField placeHolder={t("placeHolder.text")} name={"externalCode"}
                                                           label={t("externalCode.single") + ": "}
                                                           toolTip={t("externalCode.filterToolTip")}
                                                           onKeyDown={onKeyDownFilter}
                                          />
                                          <ActionDaysSelect actionId={actualAction.actualAction} type={"filter"}
                                                            toolTip={t("action.daysFilterToolTip")}
                                                            onKeyDown={onKeyDownFilter}/>
                                          {filtered && <div className={"col-start-1 lg:col-start-5 mt-6"}>
                                              <EnigooButton extraStyle={"btn-block"} color={"red"} onClick={() => {
                                                  dispatch({
                                                      type: 'SET_FILTERED',
                                                      filtered: false,
                                                  })
                                                  let newVal = {};
                                                  newVal.wasSend = undefined;
                                                  newVal.wasPrint = undefined;
                                                  newVal.groups = undefined;
                                                  dispatch({
                                                      type: 'SET_FILTER_DATA',
                                                      filterData: newVal
                                                  })
                                              }}
                                                            text={t("basics.showAll")} loading={filtering}
                                                            disabled={filtering}
                                                            icon={<FiFilter className={"mr-3"}/>}/>
                                          </div>}
                                          <div className={"col-start-2 lg:col-start-6 mt-6"}>
                                              <EnigooButton extraStyle={"btn-block"} onClick={handleSubmit}
                                                            text={t("basics.filter")} loading={filtering}
                                                            disabled={filtering || !dirty}
                                                            icon={<FiFilter className={"mr-3"}/>}/>
                                          </div>
                                      </div>
                                  </>
                              )
                          }}
                    />

                </div>
            )
        } else {
            return <></>
        }
    }

    const _renderButtons = () => (
        <div className={"flex flex-row"}>
            <VerifyRights page={["user", "emailQueue", "userImport", "user"]}
                          action={["filterUser", "sendMail", "importUser", "getUserPrintData"]}>
                <div className="mr-1">
                    <Dropdown
                        width="w-64"
                        placement={`bottom-end`}
                        disabled={printing}
                        title={printing ? t("loading.working") :
                            <span className={"uppercase"}>{t("basics.options")}</span>}>
                        {!printing && <>
                            <div className="flex flex-col text-center">
                                <VerifyRights page={"user"} action={"filterUser"}>
                                    {!openFilter && <div
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button onClick={() => {
                                            dispatch({
                                                type: 'SET_OPEN_FILTER',
                                                openFilter: !openFilter
                                            })
                                        }
                                        }
                                                className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                            <><FiFilter className={"mr-2"}/>{t("accreditation.openFilter")}</>
                                        </button>
                                    </div>}
                                </VerifyRights>
                                <VerifyRights page={"emailQueue"} action={"sendMail"}>
                                    <div
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button disabled={(!selectedRows || selectedRows.length < 1) && !selectAll}
                                                onClick={() => {
                                                    if ((!selectedRows || selectedRows.length < 1) && selectAll === false) {
                                                        CogoToast.error(t("accreditation.chooseUserToSendMail"));
                                                    } else {
                                                        setOpenModal(true);
                                                    }
                                                }}
                                                className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                            <FiSend className={"mr-2"}/>{t("accreditation.sendMail")}
                                        </button>
                                    </div>
                                </VerifyRights>
                                <VerifyRights page={"userImport"} action={"importUser"}>
                                    <div
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">

                                        <button onClick={() => history.push("/accreditation/import")}
                                                className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                            <FiUserPlus className={"mr-2"}/> {t("basics.import")}
                                        </button>
                                    </div>
                                </VerifyRights>
                                <VerifyRights page={"user"} action={"getUserPrintData"}>
                                    <div
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button disabled={(!selectedRows || selectedRows.length < 1) && !selectAll}
                                                onClick={() => {
                                                    if (filtered && selectAll) {
                                                        userPrint(selectedRows, setPrinting, actualAction, setPrintError, "print", filterData, selectAll, null, false, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                                                    } else if (selectAll) {
                                                        userPrint(selectedRows, setPrinting, actualAction, setPrintError, "print", null, true, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                                                    } else {
                                                        userPrint(selectedRows, setPrinting, actualAction, setPrintError, "print", null, selectAll, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                                                    }

                                                }}
                                                className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                            <FiPrinter
                                                className={"mr-2"}/> {printing ? t("loading.working") : (t("basics.print") +" "+getPlatformShortCut("p"))}
                                        </button>
                                    </div>
                                </VerifyRights>
                                <VerifyRights page={"user"} action={"exportUser"}>
                                    <div
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button disabled={(!selectedRows || selectedRows.length < 1) && !selectAll}
                                                onClick={() => {
                                                    if (filtered && selectAll) {
                                                        userPrint(selectedRows, setPrinting, actualAction, setPrintError, "export", filterData, selectAll);
                                                    } else if (selectAll) {
                                                        userPrint(selectedRows, setPrinting, actualAction, setPrintError, "export", null, selectAll);
                                                    } else {
                                                        userPrint(selectedRows, setPrinting, actualAction, setPrintError, "export", null, selectAll);
                                                    }
                                                }}
                                                className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                            {/*TODO: Překlad*/}
                                            <AiOutlineFilePdf
                                                className={"mr-2"}/> {printing ? "Pracuji..." : "Vygenerovat PDF"}
                                        </button>
                                    </div>
                                </VerifyRights>
                                <VerifyRights page={"user"} action={"exportUser"}>
                                    <div
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button disabled={(!selectedRows || selectedRows.length < 1) && !selectAll}
                                                onClick={() => {
                                                    if (filtered && selectAll) {
                                                        userExport(selectedRows, setPrinting, actualAction, setPrintError, filterData, selectAll, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                                                    } else if (selectAll) {
                                                        userExport(selectedRows, setPrinting, actualAction, setPrintError, null, selectAll, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                                                    } else {
                                                        userExport(selectedRows, setPrinting, actualAction, setPrintError, null, selectAll, t("accreditation.errorNoData"), t("accreditation.errorPrint"), t("accreditation.errorExport"));
                                                    }
                                                }}
                                                className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                            <AiOutlineFileExcel
                                                className={"mr-2"}/> {printing ? t("basics.working") : t("basics.export")}
                                        </button>
                                    </div>
                                </VerifyRights>
                                <VerifyRights page={"user"} action={"assignUserToGroups"}>
                                    <div
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button disabled={(!selectedRows || selectedRows.length < 1) && !selectAll}
                                                onClick={() => {
                                                    if ((!selectedRows || selectedRows.length < 1) && !selectAll) {
                                                        CogoToast.error(t("accreditation.chooseUserToAssignGroup"));
                                                    } else {
                                                        setOpenGroupModal(true);
                                                    }
                                                }}
                                                className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                            <FiUsers className={"mr-2"}/>{t("accreditation.assignGroup")}
                                        </button>
                                    </div>
                                </VerifyRights>
                                <VerifyRights page={"user"} action={"copyUserToAction"}>
                                    <div
                                        className="items-center flex flex-wrap justify-center h-8 space-y-1 dropdown-item">
                                        <button disabled={(!selectedRows || selectedRows.length < 1) && !selectAll}
                                                onClick={() => {
                                                    if ((!selectedRows || selectedRows.length < 1) && !selectAll) {
                                                        CogoToast.error(t("accreditation.chooseUserToDuplicateToAction"));
                                                    } else {
                                                        setOpenActionModal(true);
                                                    }
                                                }}
                                                className="btn text-xs btn-icon w-full btn-rounded h-full hover:bg-enigoo-500 hover:text-white">
                                            <FiCalendar className={"mr-2"}/> {t("accreditation.duplicateToAction")}
                                        </button>
                                    </div>
                                </VerifyRights>

                                <VerifyRights page={"user"} action={"assignDays"}>
                                    <UserAssignDaysModal selectedRows={selectedRows}
                                                         actionId={actualAction.actualAction} selectAll={selectAll}/>
                                </VerifyRights>
                                <VerifyRights page={"user"} action={"assignZone "}>
                                    <UserAssignZoneModal selectedRows={selectedRows}
                                                         actionId={actualAction.actualAction} selectAll={selectAll}/>
                                </VerifyRights>
                            </div>
                        </>}
                    </Dropdown>
                </div>
            </VerifyRights>
            <VerifyRights page={"user"} action={"createUser"}>
                <div>
                    <EnigooButton
                        link={"/accreditation/create"} icon={<FiPlus/>}
                        text={t("basics.add")}/>
                </div>
            </VerifyRights>
        </div>
    )


    return (
        <>
            <CopyUserToActionModal selectedIds={selectedRows} open={openActionModal} setOpen={setOpenActionModal}
                                   selectAll={selectAll}/>
            <SendMailModal selectedIds={selectedRows} open={openModal} setOpen={setOpenModal} selectAll={selectAll}/>
            <AssignUserToGroupModal setOpen={setOpenGroupModal} open={openGroupModal} selectedIds={selectedRows}
                                    selectAll={selectAll}/>
            <UserPrintErrorModal error={printError} setError={setPrintError}/>
            <EnigooBreadcrumbs right={_renderButtons()}/>
            {openFilter ? <Widget>
                {_renderFilterForm()}
            </Widget> : <></>}
            <Widget>
                {_renderBody()}
            </Widget>
        </>
    )
}

export default User;
