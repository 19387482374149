import {Field} from "react-final-form";
import PropTypes from "prop-types";
import React from "react";
import {useTranslation} from "react-i18next";

export const EnigooEmailField = ({
                                     inline = false,
                                     label,
                                     name,
                                     placeholder = 'test@nonexist.com',
                                     outsideClassName = "w-full",
                                     mandatory = false
                                 }) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,}$/;
    const {t} = useTranslation()
    const required = value => {
        if(!mandatory && !value){
            return undefined;
        } else if (mandatory && value === "" || value === null) {
            return t("basics.requireField");
        } else if (value !== "" && value !== null && !emailRegex.test(value)) {
            return t("basics.emailNotValid");
        } else if (value && !emailRegex.test(value)) {
            return t("basics.emailNotValid");
        } else {
            return undefined;
        }
    }

    return (
        <div className={outsideClassName}>
            <div className={`form-element ${inline ? 'form-element-inline' : ''}`}>
                <Field name={name} validate={required}>
                    {({input, meta}) => (
                        <>
                            <div className="form-label">{label}</div>
                            <input
                                {...input}
                                name={name}
                                type={"email"}
                                className={(meta.error && meta.touched)? "form-input form-input-invalid" : "form-input"}
                                placeholder={placeholder}
                            />
                            {meta.error && meta.touched && <div className="form-error">{meta.error}</div>}
                        </>
                    )}
                </Field>

            </div>
        </div>
    )
}
EnigooEmailField.propTypes = {
    inline: PropTypes.bool,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    outsideClassName: PropTypes.string
}
