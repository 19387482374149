import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import Routes from './Routes'
import '../css/tailwind.css'
import '../css/main.css'
import '../css/animate.css'
import '../css/_components.css'
import Login from "./pages/AdminUser/AdminUserLogin";
import {apiUserAuthoritate} from "./pages/Authorization/Actions";
import Layout1 from '../layouts/layout-1'
import {axios} from "../axiosConfig";
import {EnigooLoader} from "../MyComponent/EnigooLoader";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";


const App = () => {

    let [loading, setLoading] = useState(true)
    let [token, setToken] = useState(null)

    const history = useHistory();
    const dispatch = useDispatch();
    const {t} = useTranslation()
    useEffect(() => {
        authorizate()
    },[])


    const authorizate = () => {

        let token = localStorage.getItem('ath-acc-en')

        if(!token) {
            setLoading(false);
            history.push("/login")
            dispatch({
                type: 'RESET'
            })
        }else {
            axios.defaults.headers.common['authorization'] = token;
            apiUserAuthoritate(token, (data) => {
                dispatch({
                    type: "SET_RIGHTS",
                    rights: data.rights,
                    superuser: data.superuser,
                    manageuser: data.manageuser,
                    subject: data.subject
                });
                setToken(data.token);
                setLoading(false);
            }, error => {
                localStorage.removeItem("ath-acc-en");
                setLoading(false)
                history.push("/login");
                dispatch({
                    type: 'RESET'
                })
                window.location.reload();
            })
        }
    }


    if (loading) return <div className="min-h-screen w-full p-4"><EnigooLoader text={t("loading.page")}/></div>
    if (!token) return <Login setToken={setToken}/>
    if (token && !loading) return <Layout1><Routes/></Layout1>


}
export default App
