import React, {useEffect, useState} from 'react';
import SectionTitle from '../../../components/section-title';
import EnigooButton from "../../../MyComponent/EnigooButton";
import {Form} from "react-final-form";
import Widget from "../../../components/widget";
import {useHistory, useParams} from "react-router";
import {apiManageCreateAdminUser, apiManageGetAdminUserById, apiManageUpdateAdminUser} from "./Actions";
import CogoToast from "cogo-toast";
import {EnigooLoader} from "../../../MyComponent/EnigooLoader";
import {FiInfo, FiLock, FiSave} from "react-icons/all";
import {IconTabs} from "../../../components/tabs";
import {EnigooTextField} from "../../../MyComponent/EnigooTextField";
import {EnigooFieldsGroup} from "../../../MyComponent/EnigooFieldsGroup";
import {EnigooSwitch} from "../../../MyComponent/EnigooSwitch";
import {ManageSubjectSelectField} from "../../../MyComponent/Api/Manage/ManageSubjectSelectField";
import {useTranslation} from "react-i18next";


const AdminUserForm = () => {
    let [userData, setUserData] = useState(null);
    let [loading, setLoading] = useState(false);
    let [saving, setSaving] = useState(false);
    let [superUser, setSuperUser] = useState(false);
    let [manageUser, setManageUser] = useState(false);
    let submit;

    const history = useHistory();

    const {id} = useParams()

    useEffect(() => {
        init()
    }, [])

    const init = () => {
        if (id) {
            setLoading(true);
            apiManageGetAdminUserById(id, (data) => {
                setSuperUser(data.superuser);
                setManageUser(data.manageuser);
                setUserData(data)
                setLoading(false);
            }, err => alert(err))
        }
    }


    const onSubmit = (values) => {
        setSaving(true);
        if (id) {
            apiManageUpdateAdminUser(id, values,
                (res) => {
                    CogoToast.success("Uživatel byl úspěšně upraven");
                    setSaving(false);
                    history.push("/manage/admin-user/detail/" + res.id)
                },
                (err) => {
                    setSaving(false);
                    CogoToast.error("Uživatele se nepodařilo upravit");
                })
        } else {
            apiManageCreateAdminUser(values,
                (res) => {
                    CogoToast.success("Uživatel byl vytvořen");
                    setSaving(false);
                    history.push("/manage/admin-user/detail/" + res.id)
                },
                (err) => {
                    CogoToast.error("Uživatele se nepodařilo vytvořit");
                    setSaving(false);
                }
            )
        }

    }


    return (
        <>
            <SectionTitle title="Užvatelé" subtitle={id ? "Editace" : "Vytvoření"}
                          right={<EnigooButton onClick={() => submit()} icon={<FiSave/>} text={"Uložit"}
                                               loading={saving} disabled={saving || loading}/>}/>
            <Widget>
                {loading ? <EnigooLoader text={"Načítám data pro editaci uživatele..."}/> :
                    <div className="flex flex-col">

                        <Form onSubmit={onSubmit} initialValues={userData}
                              validate={values => {
                                  let errors = {};
                                  if (!values.login) {
                                      errors.login = "Login je povinný"
                                  }
                                  if (!values.password && !id) {
                                      errors.password = "Heslo je povinné"
                                  }
                                  return errors;
                              }}
                              render={({handleSubmit}) => {
                                  submit = handleSubmit;
                                  return (
                                      <div className="w-full">
                                          <IconTabs
                                              tabs={tabs(id, manageUser, setManageUser, superUser, setSuperUser)}/>
                                      </div>
                                  )
                              }
                              }
                        />
                    </div>}
            </Widget>
        </>
    )


}
export default AdminUserForm;

const TabBasicInfo = ({id}) => (
    <>
        <EnigooFieldsGroup
            items={[<EnigooTextField name={"login"} label={"Login: *"} disabled={id}
                                     outsideClassName={"w-full lg:w-1/2"} placeHolder={"Zadejte login"} key={"1"}/>,
                <EnigooTextField type={"password"} name={"password"} label={"Heslo: *"} key={"2"}
                                 outsideClassName={"w-full lg:w-1/2"} placeHolder={"Zadejte heslo"}/>]}
        />
        <EnigooFieldsGroup
            items={[<EnigooTextField label={"Jméno:"} name={"name"} placeHolder={"Zadejte jméno"}
                                     outsideClassName={"w-full lg:w-1/2"} key={"1"}/>,
                <EnigooSwitch label={"Aktivní: *"} name={"active"} outsideClassName={"w-full lg:w-1/2"} key={"2"}/>
            ]}/>
        <ManageSubjectSelectField isMulti={false} name={"subjectId"} label={"Subjekt: *"} required={true}/>
    </>
)

const TabRights = ({mangeUser, setManageUser, superUser, setSuperUser}) => {
    const {t} = useTranslation();
    return (
        <>
            <EnigooSwitch name={"manageuser"} label={"Manage user: "} setChecked={setManageUser}/>
            {!mangeUser ? <>
                <EnigooSwitch name={"superuser"} label={"Super admin: "} setChecked={setSuperUser}/>
                {!superUser ?
                    <div className={"w-full grid grid-cols-2"}>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>{t("accreditation.plural")}:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.user.getUser.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.user.createUser.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.user.updateUser.is`} label={t("rights.edit")+": "}/>
                                <EnigooSwitch name={`rights.user.filterUser.is`} label={t("rights.filter")+": "}/>
                                <EnigooSwitch name={`rights.user.getUserPrintData.is`} label={t("rights.print")+": "}/>
                                <EnigooSwitch name={`rights.user.assignUserToGroups.is`}
                                              label={"Hromadné přiřazení skupin: "}/>
                                <EnigooSwitch name={`rights.user.exportUser.is`} label={"Export: "}/>
                                <EnigooSwitch name={`rights.user.deleteUser.is`} label={"Smazat uživatele: "}/>
                                <EnigooSwitch name={`rights.user.checkUser.is`} label={"Kontrola akreditací: "}/>
                                <EnigooSwitch name={`rights.user.assignZone.is`} label={t("rights.zoneAssign")+": "}/>
                                <EnigooSwitch name={`rights.user.assignDays.is`} label={t("rights.daysAssign")+": "}/>

                                <EnigooSwitch name={`rights.userImport.getTemplateToImport.is`} label={"Šablona pro import: "}/>
                                <EnigooSwitch name={`rights.userImport.importUser.is`} label={"Import: "}/>

                                <EnigooSwitch name={`rights.userCreditTransaction.getUserCreditTransaction.is`} label={"Kredit: "}/>
                                <EnigooSwitch name={`rights.userCreditTransaction.updateCredit.is`} label={"Úprava kreditu: "}/>
                                <EnigooSwitch name={`rights.user.sendMail.is`} label={"Odeslání emailu: "}/>
                                <EnigooSwitch name={`rights.emailQueue.sendEmail.is`} label={"Duplikovat do akce: "}/>


                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Akce:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.action.getAction.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.action.createAction.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.action.updateAction.is`} label={t("rights.edit")+": "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Rozšíření akcí:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.actionExtension.getActionExtension.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.actionExtension.activeActionExtension.is`} label={t("basics.activate")+": "}/>
                                <EnigooSwitch name={`rights.actionExtension.updateActionExtension.is`} label={t("rights.edit")+": "}/>
                                <EnigooSwitch name={`rights.actionExtension.getUserExtension.is`} label={t("actionExtension.forUsers")+": "}/>
                                <EnigooSwitch name={`rights.actionExtension.assignUserExtension.is`} label={t("webChannels.assign")+ " (" + t("users.plural")+"): "}/>
                                <EnigooSwitch name={`rights.actionExtension.getActionExtensionReport.is`} label={t("device.reports")+": "}/>
                                <EnigooSwitch name={`rights.actionExtension.getGroupsActionExtension.is`} label={t("actionExtension.forGroups")+": "}/>
                                <EnigooSwitch name={`rights.actionExtension.assignGroupsActionExtension.is`} label={t("webChannels.assign")+ " (" + t("groups.plural")+"): "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Fotogalerie:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.image.getImage.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.image.createImage.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.image.updateImage.is`} label={"Úprava obrázku: "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Skupiny:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.groups.getGroups.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.groups.createGroups.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.groups.updateGroups.is`} label={t("rights.edit")+": "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Zóny:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.zone.getZone.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.zone.createZone.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.zone.updateZone.is`} label={t("rights.edit")+": "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Uživatelská pole:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.customField.getCustomField.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.customField.createCustomField.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.customField.updateCustomField.is`} label={t("rights.edit")+": "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Zařízení:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.device.getDevice.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.device.getDeviceKey.is`} label={"Nastavení: "}/>
                                <EnigooSwitch name={`rights.device.updateDevice.is`} label={t("rights.edit")+": "}/>

                                <EnigooSwitch name={`rights.deviceTemplate.getDeviceTemplate.is`}
                                              label={"Zobrazení pravidel: "}/>
                                <EnigooSwitch name={`rights.deviceTemplate.createDeviceTemplate.is`}
                                              label={"Přidání pravidla: "}/>
                                <EnigooSwitch name={`rights.deviceTemplate.updateDeviceTemplate.is`}
                                              label={"Editace pravidla: "}/>

                                <EnigooSwitch name={`rights.report.getReport.is`}
                                              label={"Sestavy: "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Subjekt:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.subject.getSubject.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.subject.updateSubject.is`} label={t("rights.edit")+": "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>

                            <div className={"border-t py-2 font-bold text-lg"}>Uživatelé systému:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.adminUser.getAdminUser.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.adminUser.createAdminUser.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.adminUser.updateAdminUser.is`} label={t("rights.edit")+": "}/>
                                <EnigooSwitch name={`rights.adminUser.getActualAdminUser.is`} label={"Profil: "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Webové kanály:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.webChannel.getWebChannel.is`}
                                              label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.webChannel.createWebChannel.is`}
                                              label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.webChannel.updateWebChannel.is`}
                                              label={t("rights.edit")+": "}/>
                                <EnigooSwitch name={`rights.webChannel.exportExternalCode.is`}
                                              label={"Export externích kódů: "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Šablony:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.template.getTemplate.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.template.createTemplate.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.template.updateTemplate.is`} label={t("rights.edit")+": "}/>
                                <EnigooSwitch name={`rights.template.postValidateTemplate.is`} label={"Testování šablony: "}/>
                            </div>
                        </div>
                        <div className={"flex flex-col"}>
                            <div className={"border-t py-2 font-bold text-lg"}>Emailové šablony:</div>
                            <div className={"w-full grid content-center lg:grid-cols-6 grid-cols-3 gap-2"}>
                                <EnigooSwitch name={`rights.mailTemplate.getMailTemplate.is`} label={t("rights.show")+": "}/>
                                <EnigooSwitch name={`rights.mailTemplate.createMailTemplate.is`} label={t("rights.add")+": "}/>
                                <EnigooSwitch name={`rights.mailTemplate.updateMailTemplate.is`} label={t("rights.edit")+": "}/>
                                <EnigooSwitch name={`rights.mailTemplate.testMailTemplate.is`}
                                              label={"Testovací email: "}/>
                            </div>
                        </div>
                    </div>
                    : <></>
                }</> : <></>}
        </>
    )
}

const tabs = (id, manageUser, setManageUser, superUser, setSuperUser) => [{
    index: 0,
    content: <TabBasicInfo id={id}/>,
    title: (<><FiInfo size={18} className={"stroke-current"}/><span
        className={"ml-2"}>Základní informace</span> </>)

}, {
    index: 1,
    content: <TabRights id={id} setSuperUser={setSuperUser} superUser={superUser} mangeUser={manageUser} setManageUser={setManageUser}/>,
    title: <><FiLock size={18} className={"stroke-current"}/><span className={"ml-2"}>Práva</span> </>
}];










