import React from 'react'
import ReactDOM from 'react-dom'
import {Provider, shallowEqual, useSelector} from 'react-redux'
import store from './store'
import App from './Admin/App'
import * as serviceWorker from './serviceWorker'
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom"
import AppOnline from "./Online/AppOnline";
import AppManage from "./Manage/AppManage";
import PreviewApp from "./Preview/PreviewApp";

import {persistStore} from "redux-persist";
import "./i18";
import {PersistGate} from "redux-persist/integration/react";

let per = persistStore(store);
ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={per}>
            <Router>
                <Switch>
                    <Route path={"/preview/:subjectKey/cid/:bc"} component={PreviewApp}/>
                    <Route path={"/app/online/:id"} component={AppOnline}/>
                    <Route path={"/app/online/"} component={AppOnline}/>
                    <Route path={"/manage"} component={AppManage}/>
                    <Route path={"/"} component={App}/>
                    <Route path={"*"}><Redirect to={"/"}/></Route>
                </Switch>
            </Router>
        </PersistGate>
    </Provider>
    , document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
